import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { solutions, story, team } from "../editable-stuff/config.js";
import logo from "./../assets/img/affectory_logo.png";
import { NavLink } from "./home/migration";

const Navigation = React.forwardRef((props, ref) => {

  return (
    <Navbar
      ref={ref}
      // fixed="top"
      sticky="top"
      className={`px-3  navbar-transparent`}
      expand="lg"
    >
      <Navbar.Brand
        className="navbar-brand"
        href={process.env.PUBLIC_URL + "/#home"}
      >
        <img src={logo} alt="affectory_logo" width={"80px"} />
        {/* {`${mainBody.firstName}`} */}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggler" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="navbar-nav mr-auto">
          {story.show && (
            <NavLink
              className="nav-item lead"
              href={process.env.PUBLIC_URL + "/#story"}
            >
              Who We Are
            </NavLink>
          )}
          {/* {mission.show && (
            <NavLink
              className="nav-item lead"
              href={process.env.PUBLIC_URL + "/#mission"}
            >
              Our Mission
            </NavLink>
          )} */}

          {solutions.show && (
            <NavLink
              className="nav-item lead"
              href={process.env.PUBLIC_URL + "/#poctSolutions"}
            >
              POCT Solutions
            </NavLink>
          )}
          {team.show && (
            <NavLink
              className="nav-item lead"
              href={process.env.PUBLIC_URL + "/#team"}
            >
              The Team
            </NavLink>
          )}
          <NavLink
            className="nav-item lead"
            href="#contact"
            rel="noreferrer noopener"
          >
            Get in Touch
          </NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
});

export default Navigation;
