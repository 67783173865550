import { useForm, ValidationError } from "@formspree/react";
import emoji from "react-easy-emoji";
import "./ContactForm.scss";

const ContactForm = () => {
  const [state, handleSubmit] = useForm("xdoqeeog");
  if (state.succeeded) {
    return (
      <div className="submit-message">
        <p>Thank you for your message!</p>
        <span>{emoji("We will get back to you as soon as possible 🚀")}</span>
      </div>
    );
  }
  return (
    <div id="contact" className="contact-form">
      <div className="contact-form-form">
        <form onSubmit={handleSubmit} className="form-container">
          <input
            id="name"
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <ValidationError prefix="Name" field="name" errors={state.errors} />
          <input
            id="email"
            type="email"
            name="email"
            placeholder="Your Email"
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
          <textarea
            id="message"
            name="message"
            placeholder="We are like the friend who never gets tired of your questions, so fire away!? "
            required
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
          {/* <Bounce> */}
          <button
            type="submit"
            className="w-100 p-3"
            disabled={state.submitting}
          >
            Get in touch!
          </button>
          {/* </Bounce> */}
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
