import React, { useMemo } from "react";
import { AttentionSeeker, Fade } from "react-awesome-reveal";
import { Jumbotron } from "./migration";

const NeedMore = ({ title, emoji, description, solutions }) => {
  const memoizedSolutions = useMemo(() => {
    return solutions.map((solution, index) => (
        <div className="solutions-item" key={`${solution}_${index}`}>
          <div className="solutions-item-photo"></div>
          <div className="solutions-item-name">{solution.name}</div>
          <div className="solutions-item-description">
            {solution.description}
          </div>
        </div>
    ));
  }, [solutions]);

  return (
      <Fade duration={2000}>
        <Jumbotron id="need_more_solutions" className="m-0 solutions-container">
          <div className="container solutions-content ">
            <div className="solutions-info">
              <AttentionSeeker effect="bounce">
                <h2 className="display-4 mb-3 text-center">{title}</h2>
              </AttentionSeeker>

              <p className="text-center">{description}</p>
              <div className="solutions-items">
                {memoizedSolutions}
              </div>
            </div>
          </div>
        </Jumbotron>
      </Fade>
  );
};

export default NeedMore;