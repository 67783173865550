import React from "react";
import mailboxAnimation from "../../assets/animations/mailbox-animation.json";
import DisplayLottie from "../displayLottie/DisplayLottie";

const GetInTouch = ({ heading, message, subMessage }) => {
  return (
    <div className="getintouch">
      <div className="getintouch-heading">
        <h2>Get in touch</h2>
        <div className="getintouch-heading-animation">
          <DisplayLottie animationData={mailboxAnimation} />
        </div>
      </div>
      <p className="lead text-center p-1 text-center getintouch-message">
        {message}
      </p>
      <div className="getintouch-submessage-container">
        <div className="getintouch-submessage"> {subMessage}</div>
        {/* <div className="getintouch-animation">
          <DisplayLottie animationData={heartAnimation} />
        </div> */}
      </div>
    </div>
  );
};

export default GetInTouch;
