import React, { useMemo } from "react";
import { AttentionSeeker, Fade } from "react-awesome-reveal";
import { Jumbotron } from "./migration";
import animationEmergencyParameters from "../../assets/animations/emergency-parameters-animation.json";
import DisplayLottie from "../displayLottie/DisplayLottie";

const Solutions = ({ title, emoji, description, solutions }) => {
    const memoizedSolutions = useMemo(() => {
        return solutions.map((solution, index) => (
            <div className="solutions-item" key={`${solution}_${index}`}>
                <div className="solutions-item-photo"></div>
                <div className="solutions-item-name">{solution.name}</div>
                <div className="solutions-item-description">
                    {solution.description}
                </div>
            </div>
        ));
    }, [solutions]);

    return (
        <Fade duration={2000}>
            <Jumbotron id="emergency_parameters" className="m-0 solutions-container">
                <div className="solutions-content">
                    <div className="solutions-info">
                        <div className="solution-header">
                            <AttentionSeeker effect="bounce">
                                <h2 className="display-4 mb-3 text-center">{title}</h2>
                                <div className="emergency-animation">
                                    <DisplayLottie animationData={animationEmergencyParameters} loop={true}/>
                                </div>
                            </AttentionSeeker>
                        </div>
                        <p className="text-center">{description}</p>
                        <div className="solutions-items">
                            {memoizedSolutions}
                        </div>
                    </div>
                </div>
            </Jumbotron>
        </Fade>
    );
};

export default Solutions;