import emoji from "react-easy-emoji";
import bacteria from "./../assets/img/bacteria.png";
import linkedinSVG from "./../assets/img/linkedin.svg";
import virus from "./../assets/img/virus.png";
import mariosN from "./../assets/team/marios-nikolaides.png";
import mariosS from "./../assets/team/marios-sarpetsas.png";

// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  // gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  gradientColors: "#0e0e0e, #0e0e0e, #0e0e0e",
  firstName: "Affectory",
  middleName: "",
  lastName: "Limited",
  hashtags: [
    "#pointofcaresolutions",
    "#pointofcaretesting",
    "#poctdevices",
    "#poct",
    "#nearpatienttesting",
    "#diagnostictesting",
    "#virustesting",
    "#rapidtests",
    "#emergencytestparameters",
    "#respiratoryinfections",
    "#infectiousdiseases",
    "#viralinfections",
    "#healthcaretech",
    "#patientcare",
    "#prevention",
    "#labonchip",
  ],
  quote: "Elevating Healthcare, ",
  subQuote: "One Test at a Time",
  message:
    "Welcome to Affectory. Your trusted allies in Point-of-Care Testing (POCT)!",
  icons: [
    {
      image: linkedinSVG,
      url: "https://www.linkedin.com/company/affectory/",
    },
  ],
};

// Our Story
const story = {
  show: true,
  emoji: emoji("🍻"),
  heading: "Our Story",
  imageLink: "",
  imageSize: 375,
  caption:
    "Once upon a time, in a small town, called Nicosia, a group of friends found themselves pondering life's big questions over a round of drinks at their favorite bar. Among them, were a scientist, a business-savvy entrepreneur, and a creative thinker with a knack for storytelling. Little did they know, this casual gathering would spark the beginning of an extraordinary journey. As the night wore on and the conversation flowed, they found themselves discussing the state of healthcare and the challenges faced by patients in accessing timely and accurate diagnostics. Each member of the group shared their own frustrations and aspirations, from the scientist complaining about the slow pace of traditional laboratory testing to the entrepreneur dreaming of a single device that could deliver tests results in minutes. Inspired by their collective passion and fueled by their years long friendship, they made a bold decision right then and there: they would combine their skills and expertise to create a company dedicated to revolutionizing point-of-care testing. And so, with a toast to their shared vision and a sense of excitement in the air, their journey began. Their first challenge was finding a name for their company. After much brainstorming and a fair amount of laughter, they agreed on Affectory - A factory creating solutions that affect people’s life for the better and a nod to their mission of providing fast and accurate diagnostics to those in need. Their journey will be filled with ups and downs; sleepless nights and moments of breakthrough. Along the way, they learned valuable lessons about resilience and commitment. But through it all, they never lost sight of their goal: to create a company that would make a real difference in the lives of patients and healthcare providers alike. And as they watched their company evolve into a fully functional one-stop shop for point-of-care diagnostics, they knew that their journey was only just beginning…",
  message:
    "To revolutionize healthcare by providing accessible, rapid, and reliable point-of-care testing solutions.",
};

// ABOUT SECTION
const mission = {
  show: true,
  emoji: emoji("🚀"),
  heading: "Our Mission",
  imageLink: "",
  imageSize: 375,
  caption:
    "At Affectory we are driven by a singular mission: to revolutionize healthcare by providing accessible, rapid, and reliable point-of-care testing solutions. We believe that timely and accurate diagnostic information is the key to empowering healthcare professionals and improving patient outcomes. Our team of dedicated professionals aims to push the boundaries of providing cutting-edge diagnostic solutions that bring laboratory-quality testing directly to healthcare professionals; safe, effective, fast and reliable. Here, we also believe in the power of collaboration. By creating strong partnerships with international leading experts and pioneers in the healthcare sector and point of care testing field, we aim to create a network that fosters knowledge, exchange and drives continuous improvement in the field of point-of-care testing.",
  message: "So, if this is what you are looking for, the search is over",
};

// SKILLS SECTION
const team = {
  show: true,
  heading: emoji("May the team be with you"),
  quote: "",
  members: [
    {
      name: "Marios Sarpetsas",
      image: mariosS,
      linkedIn: "https://cy.linkedin.com/in/msarpetsas",
      subName: "-- .- .-. .. --- ... / ... .- .-. .--. . - ... .- ...",
      role: "Managing Director",
      description:
        "An experienced Manager in the field with more than 8 years of continues presence in the healthcare market. Marios' educational background includes a degree in Medical Engineering, and further studied Financial Engineering. This combination of managerial experience and a dual background in medical and financial engineering positions him with a unique and valuable skill set.",
    },
    {
      name: "Marios Nicolaides",
      image: mariosN,
      linkedIn: "https://www.linkedin.com/in/marios-nicolaides2392/",
      subName: "-- .- .-. .. --- ... / -. .. -.-. --- .-.. .- .. -.. . ...",
      role: "Operations",
      description:
        "Marios possesses significant experience in the academic environment, coupled with a passion for technology as a general geek. This blend of academic background and tech enthusiasm positions him as someone with a deep understanding of both theoretical knowledge and practical applications in the ever-evolving world of technology.",
    },
  ],
};

const poctSolutionsBanner = {
  show: true,
  emoji: emoji("🥼"),
  colors: ["#2B2D42", "#E86A92", "#F7F7F9"],
  title: emoji("POCT Solutions"),
  description: "",
  solutions: [
    {
      name: "Emergency Parameters",
      description:
        "Emergency parameters in healthcare are like red flags that alert doctors when someone is really sick. If these numbers are way too high or too low, it could mean the person is in danger and needs immediate help. Basically, these parameters help doctors know when they need to act fast to save someone's life.",
      link: "#emergency_parameters",
      linkName: "",
    },
    {
      name: "Respiratory Track Infectious Diseases",
      description:
        "Respiratory tract infectious diseases are illnesses caused by microorganisms like viruses, bacteria, or fungi that infect the respiratory system, which includes the nose, throat, windpipe, and lungs.",
      link: "#track_infectious_diseases",
      linkName: "",
    },
    {
      name: "Need More?",
      description: "Our aim is to provide all rapid tests solutions.",
      link: "#need_more_solutions",
      linkName: "",
    },
  ],
};

const solutions = {
  show: true,
  emoji: emoji("✅"),
  title: emoji("Emergency Parameters"),
  description: "",
  solutions: [
    {
      name: "Brain natriuretic peptide (BNP)",
      description:
        "BNP - Brain natriuretic peptide (BNP) is a 32-amino acid polypeptide secreted by the ventricles of the heart in response to excessive stretching of heart muscle cells (cardiomyocytes). BNP has brain in its name because that's where researchers first discovered it. Your heart makes and releases BNP into your bloodstream when it's working harder than normal to pump blood. BNP test is a blood test that measures levels of a protein called BNP that is made by your heart and blood vessels. BNP levels are higher than normal when you have heart failure.",
    },
    {
      name: "D-Dimer",
      description:
        "A D-dimer test is a blood test that checks for blood-clotting problems. D-Dimer - a fibrin degradation product (or FDP), a small protein fragment present in the blood after a blood clot is degraded by fibrinolysis. The concentration of D-dimer helps to diagnose thrombosis. It is used in the diagnosis of DVT (Deep Vein Thrombosis) or PE (Pulmonary Embolism). A positive test means the D-dimer level in your body is higher than normal. It suggests you might have a blood clot or blood clotting problems.",
    },
    {
      name: "Procalcitonin (PCT)",
      description:
        "Procalcitonin (PCT) is a substance produced by many types of cells in the body, often in response to bacterial infections but also in response to tissue injury. Procalcitonin is a protein consisting of 116 amino acids and can be detected in the blood in response to bacterial infection with systemic inflammatory reactions. PCT is the most sensitive biomarker for bacterial specificity providing results to determine bacterial infection. The level of procalcitonin in the blood can increase significantly in systemic bacterial infections and sepsis.",
    },
    {
      name: "C-Reactive protein (CRP)",
      description:
        "C-Reactive protein (CRP) is a protein made by the liver and released into the blood in response to inflammation. It plays an important role in the immune process. The C-reactive protein (CRP) test is a blood test that checks for inflammation in your body. CRP can help confirm if there is inflammation in your body. It can also help to see how well you are responding to treatment. A high-sensitivity CRP (hs-CRP) can help identify future heart disease.",
    },
    {
      name: "Troponin I",
      description:
        "Troponin I test measures the levels of troponin I protein in the blood. This protein is released when the heart muscle has been damaged, such as in case of a heart attack. The more damage there is to the heart, the greater the amount of troponin I there will be in the blood.",
    },
  ],
};

const diseases = {
  show: true,
  title: "Respiratory Track Infectious Diseases",
  description: "",
  diseases: [
    {
      name: "Covid-19",
      description:
        "Coronavirus (or, as doctors and scientists call it, COVID-19) has been part of everyone’s life since mid-March 2020. The term coronavirus actually refers to a family of viruses that causes many different types of diseases, including the common cold. Its name is actually a mash-up of three words: CO stands for corona, which means crown in Latin, and the viruses are named for the crown-like spikes on their surface; VI stands for virus; and D is for disease. The 19 comes from the year 2019, when the disease was first detected.",
      animation: virus,
    },
    {
      name: "Influenza A & B",
      description:
        "Influenza A and B are the two main types that routinely spread in humans and cause seasonal flu epidemics. Type A flu or influenza A viruses are capable of infecting animals, although it is more common for people to suffer the ailments associated with this type of flu. Unlike type A flu viruses, type B flu is found only in humans. Type B flu may cause a less severe reaction than type A flu virus, but occasionally, type B flu can still be extremely harmful.",
      animation: virus,
    },
    {
      name: "Adenovirus",
      description:
        "Adenoviruses are a group of viruses that can cause mild to severe infection throughout your body but most commonly affect your respiratory system. Adenovirus infections can happen in kids of any age, but are more common in babies and young children.",
      animation: virus,
    },
    {
      name: "Streptococcus A (Strep A)",
      description:
        "Strep throat is a bacterial infection that affects the back of the throat and the tonsils, which become irritated and swollen. It causes a sore throat that's especially painful when swallowing. Strep test is done by using a throat swab to collect sample from the back of the throat and detect the presence of group A streptococcus bacteria, the most common cause of strep throat.",
      animation: bacteria,
    },
    {
      name: "Respiratory Syncytial Virus (RSV)",
      description:
        "RSV is a major cause of respiratory illness in children. The virus usually causes a common cold. But sometimes it infects the lungs and breathing passages and can cause breathing problems in infants and young children. This test looks at cells taken from fluid in your nose to see if you have respiratory syncytial virus (RSV). This test looks for antigens in these nose cells to quickly diagnose an infection.",
      animation: virus,
    },
    {
      name: "Mycoplasma Pneumoniae",
      description:
        "Mycoplasma pneumoniae is a bacterium which often causes a mild illness in older children and young adults, but it can also cause pneumonia, an infection of the lung. Infections are usually mild with upper respiratory tract infection and acute bronchitis with a cough and sore throat. Pneumonia in is possible with 5-10% of cases. Infections can occur throughout the year but tend to occur most frequently in the late summer and autumn.Most illnesses that come from Mycoplasma pneumoniae (M. pneumoniae) are mild. For this reason, doctors sometimes call them “walking pneumonia.”",
      animation: bacteria,
    },
    {
      name: "Human Rhinoviruses (hRVs)",
      description:
        "Human Rhinoviruses (hRVs) are a large group of viruses that primarily infect the upper respiratory tract, causing illnesses such as the common cold. They are highly contagious and spread through droplets when an infected person coughs or sneezes, or by touching contaminated surfaces. Symptoms include sneezing, runny nose, sore throat, cough, and sometimes fever. While usually mild, hRV infections can cause discomfort. Treatment typically involves rest, hydration, and over-the-counter medications to alleviate symptoms, as antibiotics are ineffective against viral infections like hRVs. Most cases resolve within a week, but complications can occur, especially in individuals with weakened immune systems.",
      animation: virus,
    },
    {
      name: "Parainfluenza Viruses (PIVs)",
      description:
        "A group of viruses that commonly cause respiratory infections in humans, mostly in children. They can cause symptoms ranging from mild cold-like symptoms to more severe respiratory illnesses such as croup or pneumonia. PIVs are spread through respiratory droplets from coughs and sneezes or by touching contaminated surfaces. There are several types of parainfluenza viruses, with PIV types 1, 2, and 3 being the most common. While infections with PIVs often resolve on their own without treatment, severe cases may require medical attention, especially in young children, the elderly, or individuals with weakened immune systems.",
      animation: virus,
    },
    {
      name: "Human Metapneumovirus (hMPV)",
      description:
        "Human Metapneumovirus (hMPV) is a virus that affects particularly young children, the elderly, and individuals with weakened immune systems. It was discovered relatively recently, in 2001, and is similar to the respiratory syncytial virus (RSV) in terms of symptoms and impact. Symptoms such as coughing, wheezing, fever, runny nose, and difficulty breathing are typical. These symptoms may resemble those of other respiratory illnesses such as the flu or the common cold. In severe cases, hMPV infections can lead to complications such as pneumonia or bronchiolitis. The virus spreads through respiratory droplets when an infected person coughs or sneezes, or through direct contact with contaminated surfaces. There is no specific antiviral treatment for hMPV infections, so management typically focuses on relieving symptoms and providing supportive care. Vaccination against other respiratory pathogens such as influenza and pneumococcus may help reduce the risk of complications from hMPV infection.",
      animation: virus,
    },
  ],
};

const needMore = {
  show: true,
  title: "Need More?",
  description: "",
  solutions: [
    {
      name: "Glycated hemoglobin (HbA1c)",
      description:
        "The HbA1c (hemoglobin A1c) test measures the average level of glucose (sugar) in your blood over the past 2-3 months. This is because, as glucose sticks to hemoglobin for as long as the red blood cells are alive and red blood cells live about three months.It is a useful tool for people with diabetes to monitor their long-term blood sugar control. The higher the HbA1c level, the poorer the blood sugar control, which can indicate an increased risk of diabetes-related complications.",
      animation: "virus-cell-NDUcWOxwHL",
    },
    {
      name: "U-Albumin",
      description:
        "An albumin test examines urine for a protein called albumin. Normally, albumin is present in the blood and filtered by the kidneys. When the kidneys are functioning properly, only a tiny amount of albumin is found in the urine. However, if the kidneys are damaged, higher levels of albumin can leak into the urine.",
      animation: "virus-cell-NDUcWOxwHL",
    },
    {
      name: "Calprotectin",
      description:
        "Calprotectin is there to protect your body from harm. It's a protein found in white blood cells, that defend you from infections and inflammation. When your body is under attack, like when you have inflammation in your intestines (e.g. in conditions such as Crohn's disease or ulcerative colitis), these white blood cells release calprotectin. Think of calprotectin as a signal that something might be wrong in your gut. Doctors can test your stool for calprotectin levels to see if there's inflammation present. If they find high levels of calprotectin, it suggests there might be a problem, and further investigation or treatment might be needed.",
      animation: "virus-cell-NDUcWOxwHL",
    },
    {
      name: "H. pylori",
      description:
        "H. pylori is a type of bacteria that resides in the stomach lining, and it can cause a number of digestive problems, including ulcers and stomach pain. It can spread through contaminated food, drink, or close contact with an infected person. Abdominal pain, bloating, nausea, and weight loss are possible symptoms. Diagnosis involves endoscopy or more simply blood, stool, or breath. Antibiotics and acid-suppressing drugs are often used in combination for treatment in order to eradicate the bacteria and repair the stomach lining. The risk of infection can be decreased by taking preventive steps, such as practicing excellent hygiene.",
      animation: "virus-cell-NDUcWOxwHL",
    },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "",
  message:
    "Hey there! If you are here, it means you have a Question.  We're not just in the business of great products; we're in the business of great relationships. ",
  subMessage: "Ask away, we can help!",
  email: "marios@affectory.com",
};

export {
  diseases,
  getInTouch,
  mainBody,
  mission,
  navBar,
  needMore,
  poctSolutionsBanner,
  solutions,
  story,
  team,
};
