import React, { lazy, Suspense, useMemo } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import animation from "../../assets/animations/mission.json";
import { Jumbotron } from "./migration";

const DisplayLottie = lazy(() => import("../displayLottie/DisplayLottie"));

const DisplayLottieLazy = ({ animationData, loop }) => (
    <Suspense fallback={<div>Loading animation...</div>}>
        <DisplayLottie animationData={animationData} loop={loop} />
    </Suspense>
);

const Mission = ({ heading, emoji, caption, message, link }) => {
    const memoizedHeader = useMemo(() => (
        <div className="mission-header">
            <h2 className="display-4 text-center">
                {heading}
                {emoji}
            </h2>
        </div>
    ), [heading, emoji]);

    const memoizedMessage = useMemo(() => (
        <>
            <p
                className="text-center text-wrap d-none d-sm-block"
                style={{ color: "#BD202F" }}
            >
                <strong>
                    <Fade delay={500} cascade damping={0.02} triggerOnce>
                        {message}
                    </Fade>
                </strong>
            </p>
            <p
                className="d-block d-sm-none text-center"
                style={{ color: "#BD202F" }}
            >
                <strong>{message}</strong>
            </p>
        </>
    ), [message]);

    const memoizedAnimation = useMemo(() => (
        <div className="mission-animation">
            <Slide direction="right">
                <Suspense fallback={<div>Loading animation...</div>}>
                    <DisplayLottieLazy animationData={animation} loop={true} />
                </Suspense>
            </Slide>
        </div>
    ), []);

    const memoizedFade = useMemo(() => (
        <Fade duration={2000}>
            <Jumbotron id="mission" className="m-0 mission-container">
                <div className="container mission-content ">
                    {memoizedHeader}
                    <div className="mission-info">
                        <div className="mission-info-content">
                            <Slide direction="left">
                                <p className="text-left">{caption}</p>
                                {memoizedMessage}
                            </Slide>
                        </div>
                        {memoizedAnimation}
                    </div>
                </div>
            </Jumbotron>
        </Fade>
    ), [memoizedHeader, caption, memoizedMessage, memoizedAnimation]);

    return memoizedFade;
};

export default Mission;