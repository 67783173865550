import React, { memo, useMemo } from 'react';
import { AttentionSeeker, Fade } from "react-awesome-reveal";
import { Jumbotron } from "./migration";

const Diseases = memo(({ title, description, diseases }) => {
    const memoizedDiseases = useMemo(() => {
        return diseases.map((solution, index) => (
            <div className="solutions-item" key={`${solution}_${index}`}>
                <div className="solutions-item-icon"></div>
                <div className="solutions-item-name">
                    <img src={solution.animation} alt="affectory_linkedin" width={"42px"} height={"32px"} />
                    {solution.name}
                </div>
                <div className="solutions-item-description">
                    {solution.description}
                </div>
            </div>
        ));
    }, [diseases]);

    return (
        <Fade duration={2000}>
            <Jumbotron id="track_infectious_diseases" className="m-0 solutions-container">
                <div className="solutions-content">
                    <div className="solutions-info">
                        <AttentionSeeker effect="bounce">
                            <h2 className="display-4 mb-3 text-center">{title}</h2>
                        </AttentionSeeker>
                        <p className="text-center">{description}</p>
                        <div className="solutions-items">
                            {memoizedDiseases}
                        </div>
                    </div>
                </div>
            </Jumbotron>
        </Fade>
    );
});

export default Diseases;