import React, { useMemo } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import linkedIn from "../../assets/img/linkedin_black.svg";
import vader from "../../assets/img/vader_heading.png";
import { Jumbotron } from "./migration";

const Team = ({ heading, quote, members }) => {
  const memoizedMembers = useMemo(() => {
    return members.map((member, index) => (
        <Slide
            key={`${member}_${index}`}
            duration={800}
            direction={`${index === 0 ? "left" : "right"}`}
        >
          <div className="team-member">
            <div className="team-member-photo">
              <img
                  src={member.image}
                  alt={`affectory_member_${member.name}`}
                  width={"265px"}
                  height={"400px"}
              />
            </div>
            <div className="team-member-info">
              <div className="team-member-name">{member.name}</div>
              <div className="team-member-subname">{member.subName}</div>
              <a href={member.linkedIn} target="_blank" rel="noreferrer">
                <div className="team-member-role">
                  {member.role}
                  <img
                      src={linkedIn}
                      alt="affectory_linkedin"
                      width={"24px"}
                      height={"24px"}
                  />
                </div>
              </a>
              <div className="team-member-description">{member.description}</div>
            </div>
          </div>
        </Slide>
    ));
  }, [members]);

  return (
      <Fade duration={2000}>
        <Jumbotron id="team" className="m-0 team-container">
          <div className="container team-content ">
            <div className="team-info">
              <h2 className=" mb-3  team-info">
                <img
                    src={vader}
                    alt="affectory_team"
                    width={"56px"}
                    height={"56px"}
                />
                <div className="team-heading">{heading}</div>
              </h2>
              <p className="text-center">{quote}</p>
              <div className="team-members">{memoizedMembers}</div>
            </div>
          </div>
        </Jumbotron>
      </Fade>
  );
};

export default Team;