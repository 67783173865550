import { Analytics } from "@vercel/analytics/react";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FooterComponent from "./components/Footer.jsx";
import Navbar from "./components/Navbar";
import MainBody from "./components/home/MainBody";
import Mission from "./components/home/Mission.jsx";
import Team from "./components/home/Team.jsx";
import {
  diseases,
  mainBody,
  mission,
  navBar,
  needMore,
  poctSolutionsBanner,
  solutions,
  story,
  team,
} from "./editable-stuff/config.js";
import Diseases from "./components/home/Diseases.jsx";
import NeedMore from "./components/home/NeedMore.jsx";
import POCTSolutionsBanner from "./components/home/POCTSolutionsBanner.jsx";
import Solutions from "./components/home/Solutions.jsx";
import Story from "./components/home/Story.jsx";

const Home = React.forwardRef((props, ref) => {
  return (
    <div id="home">
      <MainBody
        gradient={mainBody.gradientColors}
        title={`${mainBody.firstName} ${mainBody.middleName} ${mainBody.lastName}`}
        quote={mainBody.quote}
        subQuote={mainBody.subQuote}
        message={mainBody.message}
        hashtags={mainBody.hashtags}
        icons={mainBody.icons}
        ref={ref}
      />
      {mission.show && (
        <Mission
          heading={mission.heading}
          emoji={mission.emoji}
          caption={mission.caption}
          message={mission.message}
          link={mission.imageLink}
          imgSize={mission.imageSize}
          resume={mission.resume}
        />
      )}

      {poctSolutionsBanner.show && (
        <POCTSolutionsBanner
          heading={poctSolutionsBanner.title}
          emojis={poctSolutionsBanner.emoji}
          solutions={poctSolutionsBanner.solutions}
          colors={poctSolutionsBanner.colors}
        />
      )}

      {solutions.show && (
        <Solutions
          title={solutions.title}
          emoji={solutions.emoji}
          description={solutions.description}
          solutions={solutions.solutions}
        />
      )}
      {diseases.show && (
        <Diseases
          title={diseases.title}
          description={diseases.description}
          diseases={diseases.diseases}
        />
      )}
      {needMore.show && (
        <NeedMore
          title={needMore.title}
          description={needMore.description}
          solutions={needMore.solutions}
        />
      )}
      {team.show && (
        <Team
          heading={team.heading}
          quote={team.quote}
          members={team.members}
        />
      )}
        {story.show && (
            <Story
                heading={story.heading}
                emojis={story.emoji}
                caption={story.caption}
                message={story.message}
                link={story.imageLink}
                imgSize={story.imageSize}
                resume={story.resume}
            />
        )}
      <FooterComponent />
    </div>
  );
});

const App = () => {
  const titleRef = React.useRef();

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL + "/"}>
      {navBar.show && <Navbar ref={titleRef} />}
      <Routes>
        <Route path="/" exact element={<Home ref={titleRef} />} />
      </Routes>
      <Analytics />
    </BrowserRouter>
  );
};

export default App;
