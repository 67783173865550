import React, { useMemo } from "react";
import emoji from "react-easy-emoji";
import linkedIn from "../assets/img/linkedin.svg";
import whatsapp from "../assets/img/whatsapp_logo.png";
import { getInTouch } from "../editable-stuff/config";
import ContactForm from "./contactForm/ContactForm.jsx";
import GetInTouch from "./home/GetInTouch.jsx";

const FooterComponent = React.memo((props) => {
  const currentYear = new Date().getFullYear();

  const memoizedWorkingHours = useMemo(() => (
      <div className="footer-working-hours">
        <div className="footer-info-heading">
          {emoji("Working Hours 🕗")}
        </div>
        <div className="footer-working-hours-content">
          <div className="footer-working-hours-content-section">
            <strong>Monday - Friday:</strong> 08:00 - 17:00
          </div>
          <div className="footer-working-hours-content-section">
            <strong>Saturday:</strong> 09:00 - 13:00
          </div>
        </div>
      </div>
  ), []);

  const memoizedContactInfo = useMemo(() => (
      <div className="footer-contact">
        <div className="footer-info-heading">
          {emoji("You can always")}<br/>
          {emoji("contact us here ☎️")}
        </div>
        <div className="footer-info-content">
          <strong>{emoji("📞 Telephone:")}</strong>{" "}
          <a href="tel:+35799355740">+357 99 355740</a>
        </div>
        <div className="footer-info-content">
          <img
              src={whatsapp}
              alt="affectory_whatsapp"
              width={"24px"}
              height={"24px"}
          />
          <strong> WhatsApp:</strong>{" "}
          <a href="https://api.whatsapp.com/send?phone=35799355740">
            +357 99 355740
          </a>
        </div>
        <div className="footer-info-content">
          <img
              src={linkedIn}
              alt="affectory_linkedin"
              width={"24px"}
              height={"24px"}
          />
          <strong> LinkedIn:</strong>{" "}
          <a
              href="https://www.linkedin.com/company/affectory/"
              target="_blank"
              rel="noreferrer"
          >
            Affectory
          </a>
        </div>
        <div className="footer-info-content">
          <strong>{emoji("📩 Email: ")}</strong>
          <a href="mailto:info@affectory.com">info@affectory.com</a>
        </div>
      </div>
  ), []);

  const memoizedFooterRights = useMemo(() => (
      <div className="footer-rights" id="getintouch">
        <div className="footer-copyright">
          <small className="text-muted">
            &copy; {currentYear} Affectory Limited. All Rights Reserved
          </small>
        </div>
        <div className="footer-signature">
          {"👩🏽‍💻"} with{" ❤️ by "}
          <a
              href="https://www.houseofcode.cy"
              aria-label="houseofcode.cy"
              target={"_blank"} rel="noreferrer"
          >
            <span className="badge bg-dark">HouseOfCode</span>
          </a>
        </div>
      </div>
  ), [currentYear]);

  return (
      <div className="footer">
        <div className="footer-container mt-auto py-5 text-center">
          <div className="footer-info col-xs-12 col-lg-4">
            <div className="footer-container-new">
              {memoizedWorkingHours}
              <div className="footer-info-section">
                <div className="contact-info-content">
                  <strong>Nerd Lab: Find Us Here!</strong>
                </div>
                <div className="contact-info-content">
                  Attaleias 8a, Lakatamia 2325, Nicosia
                </div>
              </div>
            </div>
            {memoizedContactInfo}
          </div>
          <div className="footer-contact col-xs-12 col-lg-6">
            {getInTouch.show && (
                <GetInTouch
                    heading={getInTouch.heading}
                    message={getInTouch.message}
                    email={getInTouch.email}
                    subMessage={getInTouch.subMessage}
                />
            )}
            <ContactForm />
          </div>
        </div>
        {memoizedFooterRights}
      </div>
  );
});

export default FooterComponent;