import Lottie from "lottie-react";
import React, { Suspense } from "react";

const DisplayLottie = (props) => {
  const animationData = props.animationData;
  const loop = props.loop;

  return (
    <Suspense fallback={<></>}>
      <Lottie animationData={animationData} loop={loop} autoplay={true} />
    </Suspense>
  );
};

export default DisplayLottie;
